<template>
  <section class="tables new-updated-design">
      <vue-snotify></vue-snotify>
      <div class="row">
          <div class="col-lg-12 grid-margin stretch-card">
              <div class="card">
                  <div class="card-header d-flex" style="justify-content: space-between;">
                    <div>
                        <h4 class="card-title m-0">{{ $t('property_detail.Property_Detail') }}</h4>
                    </div>
                    <div>
                        <b-button size="sm" @click="editProperty()"
                                        class="mr-1 btn-info text-white">
                                        {{ $t('properties_list.Edit') }}
                                    </b-button>
                    </div>
                  </div>
                  <div class="card-body">
                      <div class="row">
                          <div class="col-md-4">
                              <p><span class="font-weight-bolder">{{ $t('properties_list.Property_Type') }}:</span> {{
                                  propertyData.property_type }} </p>
                              <p><span class="font-weight-bolder">{{ $t('properties_list.city') }}:</span> {{
                                  propertyData.city }}</p>
                              <p><span class="font-weight-bolder">{{ $t('properties_list.state') }}:</span> {{
                                  propertyData.state }}</p>
                              <p><span class="font-weight-bolder">{{ $t('property_detail.Country') }}:</span> {{
                                  propertyData.country }}</p>
                              <p><span class="font-weight-bolder">{{ $t('property_detail.Guests') }}:</span> {{
                                  propertyData.max_guests }}</p>
                          </div>
                          <div class="col-md-4">
                              <p><span class="font-weight-bolder">{{ $t('property_detail.bedrooms') }}:</span> {{
                                  propertyData.num_bedrooms }}</p>
                              <p><span class="font-weight-bolder">{{ $t('property_detail.Bedroom_Amenities')
                                      }}:</span>
                                  {{ bedroomAmenitiesDisplay }}
                              </p>
                              <p><span class="font-weight-bolder">{{ $t('property_detail.bathrooms') }}:</span> {{
                                  propertyData.num_bathrooms }}</p>
                              <p><span class="font-weight-bolder">{{ $t('property_detail.Bathroom_Amenities')
                                      }}:</span>
                                  {{ bathroomAmenitiesDisplay }}
                              </p>
                              <p><span class="font-weight-bolder">{{ $t('property_detail.Price') }}:</span> ${{
                                  propertyData.price }}</p>
                          </div>
                          <div class="col-md-4">
                              <p><span class="font-weight-bolder">{{ $t('property_detail.wifi') }}:</span> {{
                                  propertyData.wifi_available === 1 ? 'Yes' : 'No' }}</p>
                              <p><span class="font-weight-bolder">{{ $t('property_detail.kitchen') }}:</span> {{
                                  propertyData.kitchen_available === 1 ? 'Yes' : 'No' }}</p>
                              <p><span class="font-weight-bolder">{{ $t('property_detail.tv') }}:</span> {{
                                  propertyData.tv_available === 1 ? 'Yes' : 'No' }}</p>
                              <p><span class="font-weight-bolder">{{ $t('property_detail.Description') }}:</span> {{
                                  propertyData.property_description }}</p>
                          </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                            <div class="row mt-4">
                          <div class="col-md-12">
                              <!-- Images Section -->
                              <h5>{{ $t('property_detail.Property_Images') }}</h5>
                              <div class="row">
                                  <div v-for="(imagePreview, index) in propertyData.photos" :key="index" class="mb-2">
                                      <img :src="imagePreview.path" alt="Preview"
                                          style="max-width: 100px; max-height: 100px; margin-right: 5px;">
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="row mt-4">
                          <div class="col-md-12">
                              <p v-if="propertyData.approve"><span class="font-weight-bolder">{{
                                  $t('property_detail.Status') }}:</span>{{
                                          $t('property_detail.approved') }}</p>
                              <button v-else class="btn btn-success" @click="approveProperty(propertyData.id)">{{
                                  $t('property_detail.Approve') }}</button>
                          </div>
                      </div>
                        </div>
                        <div class="col-md-2">
                        </div>
                        <div class="col-md-4">
                            <div class="bar-chart">
                                <bar-chart :labels="labels" :chartData="propertyData.chartData"></bar-chart>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      
  </section>

</template>

<script>
import Vue from "vue";
import API from '@/api'
import BarChart from "../components/charts/chartjs/barChart.vue";

export default {
  name: 'property_details',
  data() {
      return {
          propertyData: {
              city: "",
              state: "",
              country: "",
              price: "",
              max_guests: "",
              num_bedrooms: "",
              num_bathrooms: "",
              propertyType: "",
              wifiAvailable: "",
              kitchen: "",
              tv: "",
              description: "",
              imagePreviews: [],
              id: "",
              approve: "",
          },
          labels: [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ] 
      }
  },
  methods: {

      getProperty() {
          const id = sessionStorage.getItem('propertyId');
          API.getProperty(id, res => {
                this.propertyData = res.data.data[0];
                const labels = [
                    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                ];
                let data = [];
                res.data.chartData.forEach(val => {
                    labels.forEach((label, index) => {
                        if (val.month === label) {
                            data[index] = Math.round(val.data);
                        } else {
                            data[index] = 0;
                        }
                    });
                });
                this.propertyData.chartData = data;
          }, err => {
              console.log(err)
          })
      },
      editProperty() {
            const id = sessionStorage.getItem('propertyId');
            this.$router.push({ name: 'add_property', params: { id: id, data: this.propertyData } });
        },
      approveProperty(id) {
          API.approveProperty(id, res => {
              if (res.status == 200) {
                  this.propertyData.approve = 1;
                  this.$swal({
                      type: 'success',
                      title: 'Success',
                      text: res.data.message,
                  })
              } else {
                  this.$swal({
                      type: 'error',
                      title: 'Oops...',
                      text: res.message,
                  })
              }
          }, err => {
              console.log(err);
          })
      }
  },

  mounted() {
      this.getProperty();
  },
  components: {
    BarChart
  },
  computed: {
      bathroomAmenitiesDisplay() {
          if (Array.isArray(this.propertyData.bathroom_Amenities)) {
              return this.propertyData.bathroom_Amenities.map(amenity => this.$t('property_detail.' + amenity)).join(', ');
          } else if (typeof this.propertyData.bathroom_Amenities === 'string') {
              try {
                  const parsedAmenities = JSON.parse(this.propertyData.bathroom_Amenities);
                  if (Array.isArray(parsedAmenities)) {
                      return parsedAmenities.map(amenity => this.$t('property_detail.' + amenity)).join(', ');
                  }
              } catch (error) {
                  console.error("Error parsing bathroom amenities:", error);
              }
          }
          return this.propertyData.bathroom_Amenities;
      },
      bedroomAmenitiesDisplay() {
          if (Array.isArray(this.propertyData.bedroom_Amenities)) {
              if (this.propertyData.bedroom_Amenities.length === 0) {
                  console.log("Empty array detected");
                  return "[]";

              }
              return this.propertyData.bedroom_Amenities.map(amenity => this.$t('property_detail.' + amenity)).join(', ');
          } else if (typeof this.propertyData.bedroom_Amenities === 'string') {
              try {
                  const parsedAmenities = JSON.parse(this.propertyData.bedroom_Amenities);
                  if (Array.isArray(parsedAmenities)) {
                      if (parsedAmenities.length === 0) {
                          console.log("Empty array detected after parsing");
                          return "[]";
                      }
                      return parsedAmenities.map(amenity => this.$t('property_detail.' + amenity)).join(', ');
                  }
              } catch (error) {
                  console.error("Error parsing bedroom amenities:", error);
              }
          }
          return this.propertyData.bedroom_Amenities;
      }
  },
  created() {
      if (this.$route.params.id) {
          sessionStorage.setItem('propertyId', this.$route.params.id);
      }
  },
}

</script>
<style>
.bar-chart {
    width: 100%;
    max-width: 400px;
}
</style>