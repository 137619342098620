<canvas ref="barChart"></canvas>

<script>
import {
  Bar
} from 'vue-chartjs'

export default {
  extends: Bar,
  props: ['chartData', 'labels'],
  data () {
    return {
      datacollection: {
        labels: this.labels,
        datasets: [{
          label: 'Total price',
          data: this.chartData,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)'
          ],
          borderColor: [
            'rgba(255,99,132,1)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }]
        },
        legend: {
          display: true
        },
        elements: {
          point: {
            radius: 0
          }
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    }
  },
  mounted () {
    this.renderChart(this.datacollection, this.options)
  },
  watch: {
    chartData(newVal) {
      if (newVal) {
        // Update the dataset data
        this.datacollection.datasets[0].data = newVal
        this.renderChart(this.datacollection, this.options)
      }
    }
  }
}
</script>
<style>
canvas {
  height: 300px; /* Adjust height here */
}
</style>